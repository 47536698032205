body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: rgb(73,127,186);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.coming-soon-container {
    margin-top: 40px;
    background-color: black;
    color: #faf7f2;
    padding: 12px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 25px;
    font-size: 30px;
}